<template>
    <van-list v-model="loading" :finished="finished" loading-text="努力加载中" @load="onLoad">
        <div v-for="item in list" :key="item.id" class="card" @click="onClick(item)">
            <div class="time">{{ formatTime(item.updatedTime) }}</div>
            <div class="detail">{{ item.execDetail }}</div>
            <div class="operator">{{ item.operateUserName }}</div>
        </div>
        <van-empty v-if="list.length == 0 && !loading" />
    </van-list>
</template>

<script>
import dayjs from "dayjs";
import { iceResList } from "@/api/hoss";
export default {
    data() {
        return {
            loading: false,
            finished: false,

            list: [],
            page: 0,
        };
    },
    created() {
        const { clientId } = this.$route.query;
        this.clientId = clientId;
    },
    methods: {
        // 破冰记录列表
        async getIceResList() {
            const { clientId, page } = this;
            const { value } = await iceResList({ clientId, page });
            this.list.push(...value.list);
            this.finished = value.lastPage;
            this.loading = false;
        },
        onLoad() {
            this.page++;
            this.getIceResList();
        },
        formatTime(t) {
            return dayjs(t).format("YYYY-MM-DD HH:mm:ss");
        },
        onClick({ id }) {
            this.$router.push({
                path: "/hoss/icebreaking",
                query: { clientId: this.clientId, id },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.card {
    font-size: 12px;
    background-color: var(--blueCardBackgroundColor);
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    .detail {
        color: var(--subTitleColor);
        line-height: 1.3em;
        height: 3.9em;
        margin: 5px 0;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .operator {
        text-align: right;
        color: var(--mainColor);
    }
}

.loading-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    span {
        margin-right: 10px;
    }
}
.van-empty {
    width: 180px;
    height: 180px;
    margin: 0 auto;
}
</style>
